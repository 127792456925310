import React, { useEffect, useState } from "react";
import {
    Col,
    Input,
    Row,
    Typography,
    message,
    Upload,
    Modal,
    Space,
    Form,
    Cascader,
    notification,
} from "antd";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { InboxOutlined, PlusOutlined } from "@ant-design/icons";
import {
    API_BASE_URL,
    FONT_SIZE,
    maxFile,
    Ncc_ID,
    TIME_DURATION,
} from "../../../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import authHeader from "../../../../api/auth_header";

import {
    Checkbox,
    Chip,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Radio,
    Select,
} from "@mui/material";
import "../../../../assets/css/supplier/GeInfo.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import InputMui from "../../../layout/InputMui";
import SingleSelectMui from "../../../layout/SingleSelectMui";
import ButtonSubmit from "../../../layout/ButtonSubmit";
const { Text } = Typography;

const { TextArea } = Input;
const { Dragger } = Upload;
const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
const GeneralInfo = ({ dvName, dvId, addId, isView, newId }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [dataDichVu, setDataDichVu] = useState([]);
    const [dataVat, setDataVat] = useState([]);
    const [vatId, setVatId] = useState(1);
    const [dataQuocGia, setDataQuocGia] = useState([]);
    const [dataTinhTP, setDataTinhTP] = useState([]);
    const [form] = Form.useForm();
    const [fileListPdf, setFileListPdf] = useState([]);
    const [id, setId] = useState(0);
    const [dmDichVuId, setDmDichVuId] = useState(0);
    const [dataWK, setdataWK] = useState({
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
    });
    // const [typeBt1, setTypeBt1] = useState("default");
    // const [typeBt2, setTypeBt2] = useState("default");
    // const [typeBt3, setTypeBt3] = useState("default");
    // const [typeBt4, setTypeBt4] = useState("default");
    // const [typeBt5, setTypeBt5] = useState("default");
    // const [typeBt6, setTypeBt6] = useState("default");
    // const [typeBt7, setTypeBt7] = useState("default");
    const [tinhId, setTinhId] = useState();
    const [huyenId, setHuyenId] = useState();
    const [xaId, setXaId] = useState();

    const [maNcc, setMaNcc] = useState("");
    const [maNccErrorText, setMaNccErrorText] = React.useState("");
    const [tenNcc, setTenNcc] = useState("");
    const [tenNccErrorText, setTenNccErrorText] = useState("");
    const [maSoThueErrorText, setMaSoThueErrorText] = useState("");
    const [quocGiaIdErrorText, setQuocGiaIdErrorText] = useState("");
    const [diaChiErrorText, setDiaChiErrorText] = useState("");
    // const [boderColorCasca, setBoderColorCasca] = useState("");
    // const [colorCasca, setColorCasca] = useState("");
    const [emailErrorText, setEmailErrorText] = useState("");
    const [sdtErrorText, setSdtErrorText] = useState("");
    const [trangThai, setTrangThai] = useState(false);
    const [maSoThue, setMaSoThue] = useState("");
    // const [quyMoPhongId, setQuyMoPhongId] = useState(null);
    // const [hangSaoId, setHangSaoId] = useState(null);
    // const [loaiKhachSanId, setLoaiKhachSanId] = useState(null);
    const [quocGiaId, setQuocGiaId] = useState(0);
    const [diaChi, setDiaChi] = useState("");
    const [diaChiCuThe, setDiaChiCuThe] = useState("");
    const [email, setEmail] = useState("");
    const [sdt, setSdt] = useState("");
    const [website, setWebsite] = useState("");
    const [fax, setFax] = useState("");
    const [moTa, setMoTa] = useState("");
    // const [isCod, setIsCod] = useState(null);
    // const [cod, setCod] = useState("");
    const [isVat, setIsVat] = useState(0);
    // const [ngayCuoiTuan, setNgayCuoiTuan] = useState({});
    // const [pdfFile, setPdfFile] = useState([]);
    // const [imgFile, setImgFile] = useState([]);
    // const [hieuLuc, setHieuLuc] = useState(0);
    const [dmDichVus, setDmDichVus] = useState([]);
    // const [dmHangDuThuyenId, setDmHangDuThuyenId] = useState([]);
    // const [dmQuyMoNhId, setDmQuyMoNhId] = useState([]);
    const [valueRadio, setValueRadio] = useState(0);
    const [valueRadioPay, setValueRadioPay] = useState("2");
    const [notify, setNotify] = useState(t("add"));
    // const location = useLocation();
    const props = {
        name: "file",
        multiple: true,
        maxCount: maxFile,
        // disabled: fileListPdf.length >= 5? true: false,
        onRemove: (file) => {
            const index = fileListPdf.indexOf(file);
            const newFileList = fileListPdf.slice();
            newFileList.splice(index, 1);
            setFileListPdf(newFileList);
        },
        beforeUpload: (file) => {
            const isPNG = file.type === "application/pdf";
            if (!isPNG) {
                message.error(`${file.name}` + " " + t("errorUploadPDF"));
            }
            const isLt2M = file.size / 1024 / 1024 < 3;
            if (!isLt2M) {
                message.error("Image must smaller than 3MB!");
            }
            return isPNG || Upload.LIST_IGNORE;
            // setFileListPdf([...fileListPdf, file]);
            // return false;
        },
        // onChange: ({ fileListPdf: newFileList }) => {
        //     setFileListPdf(newFileList);
        // },
        onChange(info) {
            let newFileList = [...info.fileList];
            newFileList = newFileList.map((file) => {
                if (file.response) {
                    // Component will show file.url as link
                    file.status = "done";
                }
                return file;
            });

            setFileListPdf(newFileList);
        },
        // fileListPdf,
    };
    useEffect(() => {
        let fileImg = [];
        let filePdf = [];
        let apiData = {
            id: dvId,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "nnc/thongtinchung/getDataById",
            data: apiData,
            headers: authHeader(),
        })
            .then(function (response) {
                //convert string to array stringObject.replace('[','').replace(']','').split(",").map(String)
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    setDmDichVuId(data.dmDichVuId);
                    setMaNcc(data.maNcc);
                    setTrangThai(data.trangThai);
                    setTenNcc(data.tenNcc);
                    setMaSoThue(data.maSoThue);
                    // setQuyMoPhongId(data.quyMoPhongId);
                    // setHangSaoId(data.hangSaoId);
                    // setLoaiKhachSanId(data.loaiKhachSanId);
                    setQuocGiaId(data.quocGiaId);
                    if (data.quocGiaId) handleChangeQG(data.quocGiaId);
                    setTinhId(data.tinhId);
                    setHuyenId(data.huyenId);
                    setXaId(data.xaId);
                    setDiaChi(data.diaChi);
                    setDiaChiCuThe(data.diaChiCuThe);
                    setEmail(data.email);
                    setSdt(data.sdt);
                    setWebsite(data.website);
                    setFax(data.fax);
                    setMoTa(data.moTa);
                    // setIsCod(data.isCod);
                    // setCod(data.cod);
                    setVatId(data.vatId);
                    setIsVat(data.isVat);
                    // setHieuLuc(data.hieuLuc);
                    for (let h = 0; h < data.pdfFile.length; h++) {
                        filePdf.push({
                            id: data.pdfFile[h].id,
                            uid: data.pdfFile[h].id,
                            name: data.pdfFile[h].tenFile,
                            status: "done",
                            thumbUrl:
                                "data:application/pdf;base64," +
                                data.pdfFile[h].base64Value,
                            preview:
                                "data:application/pdf;base64," +
                                data.pdfFile[h].base64Value,
                        });
                    }
                    setFileListPdf(filePdf);
                    if (data.imgFile != null || data.imgFile.length > 0) {
                        for (let k = 0; k < data.imgFile.length; k++) {
                            fileImg.push({
                                id: data.imgFile[k].id,
                                uid: data.imgFile[k].id,
                                name: data.imgFile[k].tenFile,
                                status: "done",
                                thumbUrl:
                                    "data:image/png;base64," +
                                    data.imgFile[k].base64Value,
                                preview:
                                    "data:image/png;base64," +
                                    data.imgFile[k].base64Value,
                            });
                        }
                    }
                    setFileList(fileImg);
                    var dmDichVusId = [];
                    if (data.dmDichVus)
                        data.dmDichVus.forEach((dt) => {
                            dmDichVusId.push(dt.id);
                        });
                    setDmDichVus(dmDichVusId);
                    // setNgayCuoiTuan(data.ngayCuoiTuan);
                    // setPdfFile(data.pdfFile);
                    // setImgFile(data.imgFile);
                    // setDmQuyMoNhId(data.dmQuyMoNhId);
                    setId(data.id);
                    setDmDichVuId(data.dmDichVuId);
                    setValueRadio(data.isCod ? 1 : 2);
                    setValueRadioPay(data.isVat ? 1 : 2);
                    // setdataWK(data.ngayCuoiTuan)
                    setVatId(data.vatId);
                    if (data.isCod) {
                        setCodTrue(data.cod);
                    } else {
                        setCodFalse(data.cod);
                    }
                    // if (data.ngayCuoiTuan) {
                    //     if (data.ngayCuoiTuan.monday) {
                    //         setTypeBt1("primary");
                    //     }
                    //     if (data.ngayCuoiTuan.tuesday) {
                    //         setTypeBt2("primary");
                    //     }
                    //     if (data.ngayCuoiTuan.wednesday) {
                    //         setTypeBt3("primary");
                    //     }
                    //     if (data.ngayCuoiTuan.thursday) {
                    //         setTypeBt4("primary");
                    //     }
                    //     if (data.ngayCuoiTuan.friday) {
                    //         setTypeBt5("primary");
                    //     }
                    //     if (data.ngayCuoiTuan.saturday) {
                    //         setTypeBt6("primary");
                    //     }
                    //     if (data.ngayCuoiTuan.sunday) {
                    //         setTypeBt7("primary");
                    //     }
                    //     setdataWK((prevState) => ({
                    //         ...prevState,
                    //         id: data.ngayCuoiTuan.id,
                    //         ncc_thong_tin_chung_id: data.ngayCuoiTuan.ncc_thong_tin_chung_id,
                    //         monday: data.ngayCuoiTuan.monday,
                    //         tuesday: data.ngayCuoiTuan.tuesday,
                    //         wednesday: data.ngayCuoiTuan.wednesday,
                    //         thursday: data.ngayCuoiTuan.thursday,
                    //         friday: data.ngayCuoiTuan.friday,
                    //         saturday: data.ngayCuoiTuan.saturday,
                    //         sunday: data.ngayCuoiTuan.sunday,
                    //     }));
                    //     if (data.id !== undefined && data.id !== null) {
                    //         setNotify(t("edit"));
                    //     }
                    // }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, [dvId]);
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmDichVu",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    // console.log("Response: ====" + response.data.data);
                    let dataDV = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataDV.push({
                                    id: data[i].id,
                                    label: data[i].ten_dich_vu,
                                });
                            }
                        }
                        setDataDichVu(dataDV);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);

    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmVat",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    // console.log("Response: ====" + response.data.data);
                    let dataVat = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataVat.push({
                                    value: data[i].id,
                                    label: data[i].vat,
                                });
                            }
                        }
                        setDataVat(dataVat);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);
    const dataTrangThai = [
        {
            label: t("deactivate"),
            value: false,
        },
        {
            label: t("active"),
            value: true,
        },
    ];
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/danhSachQuocGia",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    // console.log("Response: ====" + response.data.data);
                    let dataQG = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataQG.push({
                                    value: data[i].id,
                                    label: data[i].quoc_gia,
                                });
                            }
                        }
                        setDataQuocGia(dataQG);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);

    const onChangeRadio = (e) => {
        // console.log("radio checked", e.target.value);
        setValueRadio(e.target.value);
    };
    const onChangeRadioPay = (e) => {
        // console.log("radio checked", e.target.value);
        setValueRadioPay(e.target.value);
    };
    const [codTrue, setCodTrue] = useState(0);
    const [codFalse, setCodFalse] = useState(0);
    // const handleInputChange = (e) => {
    //     if (e.target.name === "codTrue") {
    //         setCodTrue(e.target.value);
    //     } else {
    //         setCodFalse(e.target.value);
    //     }
    // };
    const [fileList, setFileList] = useState([]);
    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(
            file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
        );
    };
    const handleChangeQG = (value) => {
        setQuocGiaId(value);
        console.log(`selected ${value}`);
        let data = {
            dm_quoc_gia_id: value,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "category/danhSachDiaDiem",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                // console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;

                    if (data.length > 0) {
                        let result = data.reduce((acc, o) => {
                            let tinhId = o.dm_tinh_id;
                            let cur = acc[tinhId];
                            if (!cur) {
                                cur = {
                                    value: o.tinh_thanhpho,
                                    label: o.tinh_thanhpho,
                                    id: o.dm_tinh_id,
                                    children: [],
                                };
                                acc[tinhId] = cur;
                            }
                            cur.children.push(o);
                            return acc;
                        }, {});
                        result = Object.values(result).map((v) => {
                            let dic = v.children.reduce((acc, o) => {
                                let huyenId = o.dm_huyen_id;
                                let cur = acc[huyenId];
                                if (!cur) {
                                    cur = {
                                        value: o.quan_huyen,
                                        label: o.quan_huyen,
                                        id: o.dm_huyen_id,
                                        children: [],
                                    };
                                    acc[huyenId] = cur;
                                }
                                cur.children.push({
                                    value: o.phuong_xa,
                                    label: o.phuong_xa,
                                    id: o.dm_xa_id,
                                });
                                return acc;
                            }, {});
                            v.children = Object.values(dic);
                            return v;
                        });
                        setDataTinhTP(result);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    };
    // const handleExit = () => {
    //     navigate("/ncc/" + dvName);
    // };
    const handleChangeImg = ({ fileList: newFileList }) => {
        console.log("handleChangeImg ====", newFileList);

        if (newFileList.length > 0) {
            for (let i in newFileList) {
                newFileList[i].status = "done";
            }
        }
        setFileList(newFileList);
    };
    const beforeUploadImg = (file) => {
        const isPNG =
            file.type === "image/png" ||
            file.type === "image/jpg" ||
            file.type === "image/jpeg" ||
            file.type === "image/giff";
        if (!isPNG) {
            message.error(`${file.name} ` + " " + t("errorUploadImg"));
        }
        const isLt2M = file.size / 1024 / 1024 < 3;
        if (!isLt2M) {
            message.error("Image must smaller than 3MB!");
        }

        return isPNG || Upload.LIST_IGNORE;
    };
    const onChangeCascader = (value, selectedOptions) => {
        setDiaChi(value ? value.join(",") : "");
        if (selectedOptions !== undefined) {
            if (selectedOptions.length > 0) {
                for (let i = 0; i < selectedOptions.length; i++) {
                    if (i === 0) {
                        setTinhId(selectedOptions[i].id);
                    }
                    if (i === 1) {
                        setHuyenId(selectedOptions[i].id);
                    }
                    if (i === 2) {
                        setXaId(selectedOptions[i].id);
                    }
                }
            }
        }
    };
    const filterCascader = (inputValue, path) => {
        path.some(
            (option) =>
                option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >
                -1
        );
    };
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );
    // const getDvId=()=>{
    //     let Id = 0;
    //     if (location.search.length > 0) {
    //         let id = location.search.split('=');
    //         Id = id[1]
    //     }
    //     return Id
    // }
    // const getDv=()=>{
    //     let name = ''
    //     if (location.pathname.length > 0) {
    //         let id = location.pathname.split('/');
    //         name = id[id.length -2]
    //     }
    //     return name
    // }
    const onFinish = async (e) => {
        e.preventDefault();
        var error = false;
        if (!maNcc) {
            setMaNccErrorText(
                t("validate") + " " + t("supply.code").toLowerCase() + "!"
            );
            error = true;
        } else {
            setMaNccErrorText("");
        }
        if (!tenNcc) {
            setTenNccErrorText(
                t("validate") + " " + t("supply.name").toLowerCase() + "!"
            );
            error = true;
        } else {
            setTenNccErrorText("");
        }
        if (!maSoThue) {
            setMaSoThueErrorText(
                t("validate") + " " + t("taxCode").toLowerCase() + "!"
            );
            error = true;
        } else {
            setMaSoThueErrorText("");
        }
        if (!sdt) {
            setSdtErrorText(
                t("validate") + " " + t("phone").toLowerCase() + "!"
            );
            error = true;
        } else {
            setSdtErrorText("");
        }
        if (!email) {
            setEmailErrorText(
                t("validate") + " " + t("email").toLowerCase() + "!"
            );
            error = true;
        } else {
            setEmailErrorText("");
        }
        if (!quocGiaId) {
            setQuocGiaIdErrorText(
                t("validate") + " " + t("location.country").toLowerCase() + "!"
            );
            error = true;
        } else {
            setQuocGiaIdErrorText("");
        }
        if (!diaChi) {
            setDiaChiErrorText(
                t("validate") + " " + t("address").toLowerCase() + "!"
            );
            error = true;
        } else {
            setDiaChiErrorText("");
        }
        if (error) return;
        let dataImg = [];
        if (fileList.length > 0) {
            for (let j in fileList) {
                dataImg.push({
                    id: fileList[j].id !== undefined ? fileList[j].id : 0,
                    tenFile: fileList[j].name,
                    base64Value: fileList[j].thumbUrl.split("base64,")[1],
                });
            }
        }
        let dataPdf = [];
        if (fileListPdf.length > 0) {
            for (let k in fileListPdf) {
                let base64 =
                    fileListPdf[k].id !== undefined
                        ? fileListPdf[k].thumbUrl
                        : await getBase64(fileListPdf[k].originFileObj);
                dataPdf.push({
                    id: fileListPdf[k].id !== undefined ? fileListPdf[k].id : 0,
                    tenFile: fileListPdf[k].name,
                    base64Value: base64.split("base64,")[1],
                });
            }
        }
        let cod = null;
        let isVat = null;
        let isCod = null;
        if (valueRadioPay !== undefined) {
            cod = valueRadioPay === 1 ? codTrue : codFalse;
            isCod = valueRadioPay === 1 ? true : false;
        }
        if (valueRadio !== undefined) {
            isVat = valueRadio === 1 ? true : false;
        }
        var dmDichVusId = [];
        if (dmDichVus)
            dmDichVus.forEach((dt) => {
                dmDichVusId.push({
                    id: dt,
                });
            });
        let data = {
            id: id,
            quocGiaId: quocGiaId,
            maNcc: maNcc,
            tenNcc: tenNcc,
            email: email,
            dmDichVuId:
                dmDichVuId !== 0 && dmDichVuId !== null
                    ? dmDichVuId
                    : Ncc_ID[dvName],
            diaChi: diaChi,
            tinhId: tinhId,
            huyenId: huyenId,
            maSoThue: maSoThue,
            sdt: sdt,
            xaId: xaId,
            pdfFile: dataPdf,
            imgFile: dataImg,
            trangThai: trangThai !== undefined ? trangThai : null,
            isVat: isVat,
            isCod: isCod,
            cod: cod,
            ngayCuoiTuan: dataWK,
            fax: fax !== undefined ? fax : null,
            diaChiCuThe: diaChiCuThe !== undefined ? diaChiCuThe : null,
            website: website !== undefined ? website : null,
            moTa: moTa !== undefined ? moTa : null,
            vatId: vatId !== undefined ? vatId : 1,
            dmQuyMoNhId: 0,
            dmPcAmThucId: 0,
            dmDichVus: dmDichVusId,
            dmHangDuThuyenId: 0,
            dmDongDuThuyenId: 0,
            quyMoPhongId: 0,
            hangSaoId: 0,
            loaiKhachSanId: 0,
        };
        console.log("Received data of form: ", JSON.stringify(data));

        axios({
            method: "post",
            url: API_BASE_URL + "nnc/thongtinchung/saveData",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("response ====: ", response);
                if (response.status === 200 && response.data.code === "00") {
                    newId(response.data.data);
                    addId(true);
                    api["success"]({
                        message: t("stour"),
                        description:
                            notify + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                } else {
                    addId(false);
                    api["error"]({
                        message: t("stour"),
                        description:
                            notify +
                            " " +
                            t("noti.error").toLowerCase() +
                            " " +
                            response.data.message,
                        duration: TIME_DURATION,
                    });
                }
            })
            .catch(function (response) {
                addId(false);
                api["error"]({
                    message: t("stour"),
                    description:
                        notify +
                        " " +
                        t("noti.error").toLowerCase() +
                        " " +
                        response.message,
                    duration: TIME_DURATION,
                });
            });
    };
    const clearData = () => {
        setDmDichVuId(0);
        setMaNcc("");
        setTrangThai(null);
        setTenNcc("");
        setMaSoThue("");
        // setHangSaoId(null);
        setQuocGiaId(null);
        setTinhId("");
        setHuyenId("");
        // setQuyMoPhongId(null);
        setXaId("");
        setDiaChi("");
        setDiaChiCuThe("");
        setEmail("");
        setSdt("");
        setWebsite("");
        setFax("");
        setMoTa("");
        // setIsCod(null);
        // setCod("");
        // setVatId(1);
        // // setValueRadio(0);
        // setHieuLuc(0);
        // setNgayCuoiTuan({});
        // setPdfFile([]);
        setFileList([]);
        // setdmPcAmThucId(data.dmPcAmThucId);
        // setDmQuyMoNhId([]);
        setDmDichVuId(0);
        setDmDichVus([]);
        setValueRadio(0);
        setValueRadioPay(0);
        setCodTrue(0);
        setCodFalse(0);
        setVatId(0);
        // setTypeBt1("default");
        // setTypeBt2("default");
        // setTypeBt3("default");
        // setTypeBt4("default");
        // setTypeBt5("default");
        // setTypeBt6("default");
        // setTypeBt7("default");
    };
    const handleClear = () => {
        form.resetFields();
        clearData();
    };

    const handleChangeDv = (event) => {
        const {
            target: { value },
        } = event;
        setDmDichVus(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value
        );
    };

    const dataRender = () => {
        let html = (
            <Space
                direction="vertical"
                size="small"
                style={{ display: "flex" }}
            >
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={18}>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <InputMui
                                    label={t("supply.code")}
                                    name="maNcc"
                                    value={maNcc}
                                    onChange={(e) => setMaNcc(e.target.value)}
                                    disabled={dvId !== 0}
                                    required
                                    error={!!maNccErrorText}
                                    helperText={maNccErrorText}
                                />
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <SingleSelectMui
                                    label={t("status")}
                                    name="trangThai"
                                    value={trangThai}
                                    onChange={(e) =>
                                        setTrangThai(e.target.value)
                                    }
                                    options={dataTrangThai}
                                    // errorText={trangThaiErrorText}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={23}>
                                <InputMui
                                    label={t("supply.name")}
                                    name="tenNcc"
                                    value={tenNcc}
                                    onChange={(e) => setTenNcc(e.target.value)}
                                    required
                                    error={!!tenNccErrorText}
                                    helperText={tenNccErrorText}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <InputMui
                                    label={t("phone")}
                                    required
                                    maxLength={11}
                                    minLength={10}
                                    value={sdt}
                                    onChange={(e) => setSdt(e.target.value)}
                                    inputProps={{
                                        inputMode: "numeric",
                                        pattern: "[0-11]*",
                                    }}
                                    error={!!sdtErrorText}
                                    helperText={sdtErrorText}
                                />
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <InputMui
                                    label={t("email")}
                                    type="email"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    error={!!emailErrorText}
                                    helperText={emailErrorText}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <InputMui
                                    label={t("taxCode")}
                                    name="maSoThue"
                                    required
                                    value={maSoThue}
                                    onChange={(e) =>
                                        setMaSoThue(e.target.value)
                                    }
                                    inputProps={{
                                        inputMode: "numeric",
                                        pattern: "[0-9]*",
                                    }}
                                    error={!!maSoThueErrorText}
                                    helperText={maSoThueErrorText}
                                />
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <InputMui
                                    label={t("website")}
                                    name="website"
                                    value={website}
                                    onChange={(e) => setWebsite(e.target.value)}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <SingleSelectMui
                                    required
                                    label={t("location.country")}
                                    name="quocGiaId"
                                    onChange={(e) =>
                                        handleChangeQG(e.target.value)
                                    }
                                    value={quocGiaId}
                                    options={dataQuocGia}
                                    errorText={quocGiaIdErrorText}
                                />
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <Cascader
                                    style={{
                                        width: "100%",
                                        borderRadius: "4px",
                                    }}
                                    size="large"
                                    options={dataTinhTP}
                                    onChange={onChangeCascader}
                                    showSearch={{
                                        filterCascader,
                                    }}
                                    status={diaChiErrorText ? "error" : ""}
                                    placeholder={
                                        t("location.city") +
                                        " - " +
                                        t("location.district") +
                                        " - " +
                                        t("location.wards")
                                    }
                                    value={diaChi ? diaChi.split(",") : []}
                                    onSearch={(value) => console.log(value)}
                                />
                                {diaChiErrorText ? (
                                    <Text type="danger">{diaChiErrorText}</Text>
                                ) : (
                                    <></>
                                )}
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <InputMui
                                    label={t("addressDetail")}
                                    value={diaChiCuThe}
                                    onChange={(e) =>
                                        setDiaChiCuThe(e.target.value)
                                    }
                                />
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <InputMui
                                    label={t("fax")}
                                    value={fax}
                                    onChange={(e) => setFax(e.target.value)}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={23}>
                                <InputMui
                                    label={t("description")}
                                    multiline
                                    rows={3}
                                    value={moTa}
                                    onChange={(e) => setMoTa(e.target.value)}
                                />
                            </Col>
                        </Row>
                        {dmDichVus && dmDichVus.length > 0 && (
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={23}>
                                    <FormControl
                                        sx={{
                                            marginBottom: 2,
                                            width: "100%",
                                            "& .MuiInputLabel-root": {
                                                fontSize: FONT_SIZE,
                                            },
                                            "& .MuiInputBase-input": {
                                                fontSize: FONT_SIZE,
                                            },
                                        }}
                                        size="small"
                                    >
                                        <InputLabel id="demo-multiple-checkbox-label">
                                            {t("typeService")}
                                        </InputLabel>
                                        <Select
                                            disabled={dvId !== 0}
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            value={dmDichVus}
                                            onChange={handleChangeDv}
                                            input={
                                                <OutlinedInput
                                                    label={t("typeService")}
                                                />
                                            }
                                            renderValue={(selected) => (
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexWrap: "wrap",
                                                        gap: 0.5,
                                                    }}
                                                >
                                                    {selected
                                                        .map((dvid) => {
                                                            console.log(
                                                                dataDichVu,
                                                                "dvid"
                                                            );
                                                            const label =
                                                                dataDichVu?.find(
                                                                    (e) =>
                                                                        e.id ===
                                                                        dvid
                                                                )?.label;
                                                            console.log(
                                                                label,
                                                                "lebel"
                                                            );

                                                            return label ? (
                                                                <Chip
                                                                    key={dvid}
                                                                    label={
                                                                        label
                                                                    }
                                                                />
                                                            ) : null;
                                                        })
                                                        .filter(Boolean)}
                                                </Box>
                                            )}
                                        >
                                            {dataDichVu.map((option) => (
                                                <MenuItem
                                                    key={option.id}
                                                    value={option.id}
                                                >
                                                    <Checkbox
                                                        checked={
                                                            dmDichVus.indexOf(
                                                                option.id
                                                            ) > -1
                                                        }
                                                    />
                                                    <ListItemText
                                                        primary={option.value}
                                                    />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={23}>
                                <Dragger {...props} fileList={fileListPdf}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">
                                        {t("ClickorDag")}
                                    </p>
                                    <p className="ant-upload-hint">
                                        {t("validateUploadPdf")}
                                    </p>
                                </Dragger>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                        <Row>
                            <Upload
                                beforeUpload={beforeUploadImg}
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={handlePreview}
                                onChange={handleChangeImg}
                            >
                                {fileList.length >= maxFile
                                    ? null
                                    : uploadButton}
                            </Upload>
                            <Modal
                                open={previewOpen}
                                title={previewTitle}
                                footer={null}
                                onCancel={handleCancel}
                            >
                                <img
                                    alt="image"
                                    style={{
                                        width: "100%",
                                    }}
                                    src={previewImage}
                                />
                            </Modal>
                        </Row>

                        <Row>
                            <Space direction="vertical">
                                <h4>{t("paymentTime")}</h4>
                                <Row>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={valueRadioPay}
                                        onChange={onChangeRadioPay}
                                    >
                                        <Space direction="horizontal">
                                            <FormControlLabel
                                                value={1}
                                                control={<Radio />}
                                                label={t("payBeforeUse")}
                                                sx={{
                                                    "& .MuiTypography-root": {
                                                        fontSize: FONT_SIZE,
                                                    },
                                                }}
                                            />
                                            <InputMui
                                                label={t("day")}
                                                name="day"
                                                value={codTrue}
                                                onChange={(e) =>
                                                    setCodTrue(e.target.value)
                                                }
                                                style={{
                                                    width: 70,
                                                    marginLeft: 10,
                                                }}
                                                inputProps={{
                                                    inputMode: "numeric",
                                                    pattern: "[0-9]*",
                                                    style: {
                                                        fontSize: FONT_SIZE,
                                                    },
                                                }}
                                                disabled={valueRadioPay !== "1"}
                                            />
                                        </Space>
                                        <Space
                                            direction="horizontal"
                                            style={{
                                                marginTop: 10,
                                            }}
                                        >
                                            <FormControlLabel
                                                value={2}
                                                control={<Radio />}
                                                label={t("payAfterUse")}
                                                sx={{
                                                    "& .MuiTypography-root": {
                                                        fontSize: FONT_SIZE,
                                                    },
                                                }}
                                            />
                                            <InputMui
                                                label={t("day")}
                                                name="day"
                                                value={codFalse}
                                                onChange={(e) =>
                                                    setCodFalse(e.target.value)
                                                }
                                                style={{
                                                    width: 70,
                                                    marginLeft: 25,
                                                }}
                                                inputProps={{
                                                    inputMode: "numeric",
                                                    pattern: "[0-9]*",
                                                    style: {
                                                        fontSize: FONT_SIZE,
                                                    },
                                                }}
                                                disabled={
                                                    String(valueRadioPay) !==
                                                    "2"
                                                }
                                            />
                                        </Space>
                                    </RadioGroup>
                                </Row>
                            </Space>
                        </Row>

                        <Row style={{ marginTop: "5%" }}>
                            <Col xs={12} sm={12} md={12} lg={12} xl={10}>
                                <SingleSelectMui
                                    label={t("tax") + " " + t("vat")}
                                    name="vatId"
                                    onChange={(e) => setVatId(e.target.value)}
                                    value={vatId}
                                    options={dataVat}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={valueRadio}
                                    onChange={onChangeRadio}
                                >
                                    <FormControlLabel
                                        value={1}
                                        control={<Radio />}
                                        label={t("priceIncludeTax")}
                                        sx={{
                                            "& .MuiTypography-root": {
                                                fontSize: FONT_SIZE,
                                            },
                                        }}
                                    />
                                    <FormControlLabel
                                        value={2}
                                        control={<Radio />}
                                        label={t("priceNotIncludeTax")}
                                        sx={{
                                            "& .MuiTypography-root": {
                                                fontSize: FONT_SIZE,
                                            },
                                        }}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Row>
                    </Col>
                </Row>
            </Space>
        );
        return html;
    };

    console.log(dmDichVus, "dmDichVus");
    // const [bottom, setBottom] = React.useState(50);
    return (
        <Box component="form" noValidate>
            {contextHolder}
            {dataRender()}
            <ButtonSubmit
                onFinish={onFinish}
                handleClear={handleClear}
                handleCancels={handleCancel}
            />
        </Box>
    );
};
export default GeneralInfo;
