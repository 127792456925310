import { MinusCircleOutlined } from "@ant-design/icons";
import { Box, Button, MenuItem } from "@mui/material";
import {
    Card,
    Cascader,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    Modal,
    Row,
    Space,
    Switch,
    Typography,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import authHeader from "../../../../../api/auth_header";
import "../../../../../assets/css/supplier/oto/dvCc/modal.css";
import {
    API_BASE_URL,
    data_getDM,
    FONT_SIZE,
    MAX_HEIGHT_SELECT,
    Ncc_ID,
} from "../../../../../constants";
import ButtonSubmit from "../../../../layout/ButtonSubmit";
import InputMui from "../../../../layout/InputMui";
import SingleSelectMui from "../../../../layout/SingleSelectMui";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddBoxIcon from "@mui/icons-material/AddBox";
import TableModalLT from "./TableModalLT";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";

const { Text } = Typography;

const { RangePicker } = DatePicker;

const ModalPRAction = ({
    isOpen,
    handleCancel,
    handleFinish,
    record,
    action,
    dvId,
}) => {
    const [open, setOpen] = useState(isOpen);
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [tiGia, setTiGia] = useState([]);
    const [id, setId] = useState(0);
    const [maDichVu, setMaDichVu] = useState("");
    const [dataDichVu, setDataDichVu] = useState([]);
    const [soNgay, setSoNgay] = useState("");
    const [soDem, setSoDem] = useState("");
    const [tenDichVu, setTenDichVu] = useState("");
    const [dmTyGiaId, setDmTyGiaId] = useState("");
    const [ghiChu, setGhiChu] = useState("");
    const [timeErr, setTimetErr] = useState(false);
    const [maDichVuErr, setMaDichVuErr] = useState(false);
    const [soNgayErr, setSoNgayErr] = useState(false);
    const [soDemErr, setSoDemErr] = useState(false);
    const [tenDichVuErr, setTenDichVuErr] = useState(false);
    const [dmDichVus, setDmDichVus] = useState([]);
    const [dataQuocGia, setDataQuocGia] = useState([]);
    const [dataTinhTP, setDataTinhTP] = useState([]);
    const [diemDi, setDiemDi] = useState();
    const [quocGiaId, setQuocGiaId] = useState("");
    const [diaDiem, setDiaDiem] = useState(null);
    const [dataSource, setDataSource] = useState([]);
    const [heightScroll, setHeightScroll] = useState(120);
    const [dataTable, setDataTable] = useState([]);
    const [items, setItems] = useState([
        {
            denDmQuocGiaId: 0,
            denDmTinhId: 0,
            denDmHuyenId: 0,
            denDmXaId: 0,
            diemDen: "",
            TinhTp: "",
        },
    ]);
    useEffect(() => {
        // handleClear()
        apiGetData(record.idLT);
    }, [record.idLT]);
    const apiGetData = useCallback((newId) => {
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/dvLandTour/getDataById",
            data: {
                id: newId,
            },
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    setId(data.id);
                    setSoNgay(data.soNgay);
                    setSoDem(data.soDem);
                    setDmTyGiaId(data.setDmTyGiaId);
                    setMaDichVu(data.maDichVu);
                    setTenDichVu(data.tenDichVu);
                    setGhiChu(data.ghiChu);
                    setDiaDiem(data.diemDi);
                    setQuocGiaId(data.diDmQuocGiaId);
                    setDiemDi({
                        quocGiaId: data.diDmQuocGiaId,
                        diaDiem: data.diemDi,
                        tinhId: data.diDmTinhId,
                        huyenId: data.diDmHuyenId,
                        xaId: data.diDmXaId,
                    });
                    //setDmDichVus(data.dmDichVus)
                    // setItems(data.nccDiemDenLandTourRequests);
                    let list = [];
                    let dts = [];
                    console.log("Response: ====" + data);
                    if (data.tranxDvLandTourTimeRequests.length > 0) {
                        let dataFit = data.tranxDvLandTourTimeRequests;
                        for (let k = 0; k < dataFit.length; k++) {
                            var tuNgay = dataFit[k].tuNgay.split("/");
                            var denNgay = dataFit[k].denNgay.split("/");
                            list.push({
                                stt: k + 1,
                                id: dataFit[k].id,
                                nccDichVuLandTourId:
                                    dataFit[k].nccDichVuLandTourId,
                                rangeTime: [
                                    dayjs(
                                        new Date(
                                            tuNgay[2],
                                            tuNgay[1] - 1,
                                            tuNgay[0]
                                        )
                                    ),
                                    dayjs(
                                        new Date(
                                            denNgay[2],
                                            denNgay[1] - 1,
                                            denNgay[0]
                                        )
                                    ),
                                ],
                                trangThaiBooking: dataFit[k].trangThaiBooking,
                                tranxKhoangKhachLandTourRequests:
                                    dataFit[k].tranxKhoangKhachLandTourRequests,
                            });
                            dts.push({
                                key: k,
                                stt: k + 1,
                                data: dataFit[k]
                                    .tranxKhoangKhachLandTourRequests,
                            });
                        }
                    }
                    setDataSource(dts);
                    setDataTable(list);
                    form.setFieldsValue({
                        items: list,
                    });
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    }, []);

    useEffect(() => {
        handleClear();
    }, [isOpen]);
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/danhSachQuocGia",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let dataQG = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataQG.push({
                                    value: data[i].id,
                                    label: data[i].quoc_gia,
                                });
                            }
                        }
                        setDataQuocGia(dataQG);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmTyGia",
            data: data_getDM,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    let dataLst = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataLst.push({
                                value: data[i].id,
                                label: data[i].loai_tien,
                            });
                        }
                        setTiGia(dataLst);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    }, []);
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmDichVu",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    let dataDV = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (
                                data[i].hieu_luc === true &&
                                data[i].id !== Ncc_ID["land-tour"] &&
                                data[i].id !== Ncc_ID["huong-dan-vien-tu-do"]
                            ) {
                                dataDV.push({
                                    id: data[i].id,
                                    value: data[i].ten_dich_vu,
                                });
                            }
                        }
                        setDataDichVu(dataDV);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);
    const handleChangeDv = (event) => {
        const {
            target: { value },
        } = event;
        setDmDichVus(typeof value === "string" ? value.split(",") : value);
    };
    const handleChangeQG_DiemDi = (e) => {
        setQuocGiaId(e.target.value);
        let data = {
            dm_quoc_gia_id: e.target.value,
        };
        console.log(data);

        axios({
            method: "post",
            url: API_BASE_URL + "category/danhSachDiaDiem",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ==== danhSachDiaDiem", response);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;

                    if (data.length > 0) {
                        let result = data.reduce((acc, o) => {
                            let tinhId = o.dm_tinh_id;
                            let cur = acc[tinhId];
                            if (!cur) {
                                cur = {
                                    value: o.tinh_thanhpho,
                                    label: o.tinh_thanhpho,
                                    id: o.dm_tinh_id,
                                    children: [],
                                };
                                acc[tinhId] = cur;
                            }
                            cur.children.push(o);
                            return acc;
                        }, {});
                        result = Object.values(result).map((v) => {
                            let dic = v.children.reduce((acc, o) => {
                                let huyenId = o.dm_huyen_id;
                                let cur = acc[huyenId];
                                if (!cur) {
                                    cur = {
                                        value: o.quan_huyen,
                                        label: o.quan_huyen,
                                        id: o.dm_huyen_id,
                                        children: [],
                                    };
                                    acc[huyenId] = cur;
                                }
                                cur.children.push({
                                    value: o.phuong_xa,
                                    label: o.phuong_xa,
                                    id: o.dm_xa_id,
                                });
                                return acc;
                            }, {});
                            v.children = Object.values(dic);
                            return v;
                        });
                        setDataTinhTP(result);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    };

    const handleAddField = () => {
        setItems([
            ...items,
            {
                denDmQuocGiaId: 0,
                denDmTinhId: 0,
                denDmHuyenId: 0,
                denDmXaId: 0,
                diemDen: "",
                TinhTp: "",
            },
        ]);
        setHeightScroll(heightScroll + 100);
    };
    const validate_table = (dataTable) => {
        var err = [];
        for (let i = 0; i < dataTable.length; i++) {
            const dt = dataTable[i];
            if (!dt.dmLoaiKhachId || !dt.soKhachToiThieu) {
                err.push(t("chuaNhapDuTruong"));
                break;
            } else {
                for (let j = i + 1; j < dataTable.length; j++) {
                    if (
                        dataTable[j].soKhachToiThieu === dt.soKhachToiThieu &&
                        dataTable[j].soKhachToiDa === dt.soKhachToiDa
                    ) {
                        err.push(
                            t("trungKhoangKhach") +
                                " " +
                                (i + 1) +
                                " và " +
                                (j + 1)
                        );
                        break;
                    }
                    // debugger;
                    if (dataTable[j].soKhachToiThieu === dt.soKhachToiDa) {
                        err.push(
                            t("trungKhachToiThieuHoacToiDa") +
                                " " +
                                (i + 1) +
                                " và " +
                                (j + 1)
                        );
                        break;
                    }
                }
                if (dt.foc >= dt.soKhachToiThieu) {
                    err.push(t("checkSoKhachFOC"));
                    break;
                }
            }
        }
        if (err.length < 1) {
            for (let k = 0; k < dataTable.length; k++) {
                for (let h = k + 1; h < dataTable.length; h++) {
                    const {
                        soKhachToiThieu: minI,
                        soKhachToiDa: maxI,
                        stt: sttI,
                    } = dataTable[k];
                    const {
                        soKhachToiThieu: minJ,
                        soKhachToiDa: maxJ,
                        stt: sttJ,
                    } = dataTable[h];

                    // Check if ranges overlap
                    if (minI <= maxJ && minJ <= maxI) {
                        err.push(
                            t("error") +
                                `: stt ${sttI} và stt ${sttJ} bị lồng số khách.`
                        );
                    }
                }
            }
        }
        return err;
    };
    const handleChangeQG_item = (e, i) => {
        // setQuocGiaId(e.target.value);
        setDiemDi(null);
        handleItemChange(e.target.name, i, e.target.value);
        let data = {
            dm_quoc_gia_id: e.target.value,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "category/danhSachDiaDiem",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;

                    if (data.length > 0) {
                        let result = data.reduce((acc, o) => {
                            let tinhId = o.dm_tinh_id;
                            let cur = acc[tinhId];
                            if (!cur) {
                                cur = {
                                    value: o.tinh_thanhpho,
                                    label: o.tinh_thanhpho,
                                    id: o.dm_tinh_id,
                                    children: [],
                                };
                                acc[tinhId] = cur;
                            }
                            cur.children.push(o);
                            return acc;
                        }, {});
                        result = Object.values(result).map((v) => {
                            let dic = v.children.reduce((acc, o) => {
                                let huyenId = o.dm_huyen_id;
                                let cur = acc[huyenId];
                                if (!cur) {
                                    cur = {
                                        value: o.quan_huyen,
                                        label: o.quan_huyen,
                                        id: o.dm_huyen_id,
                                        children: [],
                                    };
                                    acc[huyenId] = cur;
                                }
                                cur.children.push({
                                    value: o.phuong_xa,
                                    label: o.phuong_xa,
                                    id: o.dm_xa_id,
                                });
                                return acc;
                            }, {});
                            v.children = Object.values(dic);
                            return v;
                        });
                        handleItemChange("TinhTp", i, result);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    };
    const handleRemoveField = (index) => {
        const newItems = [...items];
        newItems.splice(index, 1);
        setItems(newItems);
        setHeightScroll(heightScroll - 100);
    };

    const handleItemChange = (name, index, value) => {
        const newItems = [...items];
        newItems[index][name] = value;
        setItems(newItems);
    };
    // const handleChaneDiemDen = (value, index) => {
    //     setItems(
    //         items.map((it, ind) => {
    //             if (ind === index)
    //                 return {
    //                     ...it,
    //                     diaChiCuThe: value,
    //                 };
    //             else return it;
    //         })
    //     );
    // };
    const onFinish = (values) => {
        setTimetErr(false);
        setTenDichVuErr(false);
        setMaDichVuErr(false);
        setSoNgayErr(false);
        setSoDemErr(false);
        let error = false;
        if (!maDichVu) {
            setMaDichVuErr(true);
            error = true;
        }
        if (!tenDichVu) {
            setTenDichVuErr(true);
            error = true;
        }
        if (!soNgay) {
            setSoNgayErr(true);
            error = true;
        }
        if (!soDem) {
            setSoDemErr(true);
            error = true;
        }
        let tranxDvLandTourTimeRequests = [];
        let timeErr = [];
        if (values.items) {
            for (let i = 0; i < values.items.length; i++) {
                const element = values.items[i];
                let time = element.rangeTime ? false : true;

                timeErr.push({
                    time: time,
                });
                if (!time) {
                    error = false;
                    const errors = [];
                    let tranxKhoangKhachLandTourRequests = [];
                    if (dataSource) {
                        if (dataSource.length > 0) {
                            let dataLT = dataSource[i].data;
                            const txtError = validate_table(dataLT);
                            if (txtError.length > 0) {
                                errors.push({
                                    name: ["items", i, "error"],
                                    errors: txtError,
                                });
                                error = true;
                                // Cập nhật lỗi vào form
                                form.setFields(errors);
                            } else {
                                if (dataLT.length > 0) {
                                    for (let j = 0; j < dataLT.length; j++) {
                                        tranxKhoangKhachLandTourRequests.push({
                                            dmLoaiKhachId:
                                                dataLT[j].dmLoaiKhachId,
                                            giaBan: dataLT[j].giaBan,
                                            giaNet: dataLT[j].giaNet,
                                            soKhachToiThieu:
                                                dataLT[j].soKhachToiThieu,
                                            soKhachToiDa:
                                                dataLT[j].soKhachToiDa,
                                            tranxLandTourId: element.id,
                                            id: isNumeric(dataLT[j].id)
                                                ? dataLT[j].id
                                                : 0,
                                        });
                                    }
                                }
                            }
                        }
                    }
                    tranxDvLandTourTimeRequests.push({
                        id: element.id,
                        nccDichVuLandTourId: element.nccDichVuLandTourId,
                        tuNgay: element.rangeTime[0].format("DD/MM/YYYY"),
                        denNgay: element.rangeTime[1].format("DD/MM/YYYY"),
                        trangThaiBooking:
                            element.trangThaiBooking ||
                            element.trangThaiBooking === undefined,
                        tranxKhoangKhachLandTourRequests:
                            tranxKhoangKhachLandTourRequests,
                    });
                } else error = true;
            }
        }
        setTimetErr(timeErr);
        if (error) return;
        let dataDv = [];
        if (dmDichVus.length > 0) {
            for (let i = 0; i < dmDichVus.length; i++) {
                dataDv.push({
                    id: dmDichVus[i],
                    tenDichVu: dataDichVu[dmDichVus[i]].label,
                });
            }
        }
        let data = {
            id: id, // thêm mới để 0, update truyền id đúng vào
            nccThongTinChungId: dvId,
            dmTyGiaId: dmTyGiaId ? dmTyGiaId : 1,
            maDichVu: maDichVu,
            tenDichVu: tenDichVu,
            diemDi: diemDi !== null ? diemDi.diaDiem : "",
            diDmQuocGiaId: quocGiaId,
            diDmTinhId: diemDi !== null ? diemDi.tinhId : "",
            diDmHuyenId: diemDi !== null ? diemDi.huyenId : "",
            diDmXaId: diemDi !== null ? diemDi.xaId : "",
            soNgay: soNgay,
            soDem: soDem,
            nccDiemDenLandTourRequests: items,
            ghiChu: ghiChu,
            tranxDvLandTourTimeRequests: tranxDvLandTourTimeRequests,
            dmDichVus: dataDv,
        };
        handleFinish(open, data);
    };
    const onChangeCascader_diemDi = (value, selectedOptions) => {
        console.log(value, selectedOptions);
        let tinhId = 0;
        let huyenId = 0;
        let xaId = 0;
        if (selectedOptions !== undefined && value !== undefined) {
            if (selectedOptions.length > 0) {
                for (let i = 0; i < selectedOptions.length; i++) {
                    if (i === 0) {
                        tinhId = selectedOptions[i].id;
                    }
                    if (i === 1) {
                        huyenId = selectedOptions[i].id;
                    }
                    if (i === 2) {
                        xaId = selectedOptions[i].id;
                    }
                }
            }
            setDiaDiem(value.join(","));
            setDiemDi({
                quocGiaId: quocGiaId,
                diaDiem: value.join(","),
                tinhId: tinhId,
                huyenId: huyenId,
                xaId: xaId,
            });
        } else {
            setDiaDiem("");
            setDiemDi(null);
        }
    };
    const filterCascader = (inputValue, path) => {
        path.some(
            (option) =>
                option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >
                -1
        );
    };
    const handleCancels = () => {
        handleClear();
        form.resetFields();
        handleCancel();
    };
    const onChangeCascader_item = (value, selectedOptions, index) => {
        if (selectedOptions !== undefined) {
            if (selectedOptions.length > 0) {
                for (let i = 0; i < selectedOptions.length; i++) {
                    if (i === 0) {
                        handleItemChange(
                            "denDmTinhId",
                            index,
                            selectedOptions[i].id
                        );
                    }
                    if (i === 1) {
                        handleItemChange(
                            "denDmHuyenId",
                            index,
                            selectedOptions[i].id
                        );
                    }
                    if (i === 2) {
                        handleItemChange(
                            "denDmXaId",
                            index,
                            selectedOptions[i].id
                        );
                    }
                    handleItemChange("diemDen", index, value.join(","));
                }
            }
        } else {
            // handleItemChange("tinhId", index, "");
            // handleItemChange("huyenId", index, "");
            // handleItemChange("xaId", index, "");
            handleItemChange("diemDen", index, "");
        }
    };
    const isNumeric = (str) => {
        if (typeof str != "string") return false; // we only process strings!
        return (
            !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str))
        ); // ...and ensure strings of whitespace fail
    };
    const handleSetData = (newData) => {
        setDataSource((prevData) => {
            // Kiểm tra xem key đã tồn tại hay chưa
            const existingIndex = prevData.findIndex(
                (item) => item.key === newData.key
            );

            if (existingIndex !== -1) {
                // Nếu key đã tồn tại, cập nhật phần tử đó
                return prevData.map((item, index) =>
                    index === existingIndex
                        ? { ...item, data: newData.data }
                        : item
                );
            } else {
                // Nếu key chưa tồn tại, thêm mới vào danh sách
                return [...prevData, newData];
            }
        });

        // Kiểm tra sau khi cập nhật
        console.log("Updated dataSource:", dataSource);
    };
    const dataRender = () => {
        let html;
        html = (
            <>
                <Form.Item name="id" hidden>
                    <Input />
                </Form.Item>
                <Form.Item name="nccDichVuLandTourId" hidden>
                    <Input />
                </Form.Item>

                <Row className="">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.List name="items">
                            {(fields, { add, remove }) => (
                                <div
                                    style={{
                                        display: "flex",
                                        rowGap: 16,
                                        flexDirection: "column",
                                    }}
                                >
                                    {fields.map(
                                        ({ key, name, ...restField }) => (
                                            <Card size="large" key={key}>
                                                <Row>
                                                    <Col
                                                        xs={18}
                                                        sm={18}
                                                        md={18}
                                                        lg={18}
                                                        xl={18}
                                                    >
                                                        <Row
                                                            gutter={8}
                                                            align="middle"
                                                        >
                                                            <Col span={6}>
                                                                <label
                                                                    style={{
                                                                        fontSize:
                                                                            FONT_SIZE,
                                                                        fontWeight: 500,
                                                                    }}
                                                                >
                                                                    {t(
                                                                        "hotel.priceRoom.applicationTime"
                                                                    ) + " *"}
                                                                </label>
                                                            </Col>
                                                            <Col span={18}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[
                                                                        name,
                                                                        "rangeTime",
                                                                    ]}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                t(
                                                                                    "validate"
                                                                                ) +
                                                                                " " +
                                                                                t(
                                                                                    "hotel.priceRoom.applicationTime"
                                                                                ).toLowerCase(),
                                                                        },
                                                                    ]}
                                                                    style={{
                                                                        marginBottom: 0,
                                                                    }}
                                                                >
                                                                    <RangePicker
                                                                        style={{
                                                                            width: "100%",
                                                                        }}
                                                                        placeholder={[
                                                                            t(
                                                                                "toDate"
                                                                            ),
                                                                            t(
                                                                                "fromDate"
                                                                            ),
                                                                        ]}
                                                                        disabledDate={(
                                                                            current
                                                                        ) => {
                                                                            let customDate =
                                                                                moment().format(
                                                                                    "DD-MM-YYYY"
                                                                                );
                                                                            return (
                                                                                current &&
                                                                                current <
                                                                                    moment(
                                                                                        customDate,
                                                                                        "DD-MM-YYYY"
                                                                                    )
                                                                            );
                                                                        }}
                                                                        format="DD/MM/YYYY"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Col
                                                        xs={3}
                                                        sm={3}
                                                        md={3}
                                                        lg={3}
                                                        xl={3}
                                                    ></Col>
                                                    <Col
                                                        xs={5}
                                                        sm={5}
                                                        md={5}
                                                        lg={5}
                                                        xl={2}
                                                    >
                                                        <MinusCircleOutlined
                                                            className="dynamic-delete-button"
                                                            onClick={() =>
                                                                remove(name)
                                                            }
                                                        />
                                                    </Col>
                                                </Row>

                                                <Row
                                                    style={{
                                                        marginTop: "15px",
                                                    }}
                                                >
                                                    <TableModalLT
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        keyNum={key}
                                                        setDataEdit={
                                                            handleSetData
                                                        }
                                                        dataS={
                                                            dataTable[key]
                                                                ?.tranxKhoangKhachLandTourRequests ||
                                                            []
                                                        }
                                                    />
                                                </Row>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "error"]}
                                                    rules={[
                                                        {
                                                            required: false,
                                                        },
                                                    ]}
                                                    style={{ marginBottom: 0 }}
                                                >
                                                    <Text type="danger" />
                                                </Form.Item>
                                                <Row
                                                    style={{
                                                        marginTop: "15px",
                                                    }}
                                                    align="middle"
                                                >
                                                    <Col span={4}>
                                                        <label
                                                            style={{
                                                                fontSize:
                                                                    FONT_SIZE,
                                                                fontWeight: 500,
                                                            }}
                                                        >
                                                            {t("status") +
                                                                " booking"}
                                                        </label>
                                                    </Col>
                                                    <Col span={16}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[
                                                                name,
                                                                "trangThaiBooking",
                                                            ]}
                                                            valuePropName="checked"
                                                            style={{
                                                                marginBottom: 0,
                                                            }}
                                                        >
                                                            <Switch
                                                                defaultChecked={
                                                                    true
                                                                }
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        )
                                    )}
                                    <Row>
                                        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                            {/*<Button onClick={() => add()} block>*/}
                                            {/*    {t("add") + " " + t("time").toLowerCase()}*/}
                                            {/*</Button>*/}
                                            <Button
                                                onClick={() => {
                                                    add();
                                                    setDataSource(
                                                        (prevData) => [
                                                            ...prevData,
                                                        ]
                                                    ); // Giữ nguyên dữ liệu cũ
                                                }}
                                                block
                                            >
                                                {t("add") +
                                                    " " +
                                                    t("time").toLowerCase()}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </Form.List>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <TextArea
                            value={ghiChu}
                            onChange={(e) => setGhiChu(e.target.value)}
                            placeholder={t("notes")}
                            autoSize={{
                                minRows: 3,
                                maxRows: 5,
                            }}
                        />
                    </Col>
                </Row>
            </>
        );
        return html;
    };
    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 10,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 14,
            },
        },
    };

    const handleClear = () => {
        setId(0);
        setMaDichVu("");
        setTenDichVu("");
        setDmTyGiaId("");
        setGhiChu("");
        setDiemDi("");
        setSoNgay("");
        setSoDem("");
        form.resetFields();
    };
    const onFinishFailed = ({ values, errorFields, outOfDate }) => {
        setTimetErr(false);
        setTenDichVuErr(false);
        setMaDichVuErr(false);
        setSoNgayErr(false);
        setSoDemErr(false);
        if (!maDichVu) {
            setMaDichVuErr(true);
        }
        if (!tenDichVu) {
            setTenDichVuErr(true);
        }
        if (!soNgay) {
            setSoNgayErr(true);
        }
        if (!soDem) {
            setSoDemErr(true);
        }
        var timeErr = [];
        for (let i = 0; i < values.items.length; i++) {
            const element = values.items[i];
            var time = element.rangeTime ? false : true;

            timeErr.push({
                time: time,
            });
        }
        setTimetErr(timeErr);
    };
    return (
        <div>
            <Modal
                title={t("edit") + " " + t("service").toLowerCase()}
                open={isOpen}
                // onOk={onFinish}
                onCancel={handleCancels}
                footer={null}
                width={1300}
            >
                <Divider />
                <Box component="form" noValidate>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <InputMui
                                label={t("res.code")}
                                name="maNcc"
                                value={maDichVu ? maDichVu : ""}
                                onChange={(e) => setMaDichVu(e.target.value)}
                                required
                                error={maDichVuErr}
                                helperText={
                                    maDichVuErr
                                        ? t("validate") +
                                          " " +
                                          t("res.code").toLowerCase()
                                        : ""
                                }
                            />
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                            <InputMui
                                label={t("soNgay")}
                                name="soNgay"
                                value={soNgay ? soNgay : ""}
                                onChange={(e) => setSoNgay(e.target.value)}
                                required
                                type="number"
                                error={!!soNgayErr}
                                helperText={soNgayErr}
                            />
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                            <InputMui
                                label={t("soDem")}
                                name="soDem"
                                value={soDem ? soDem : ""}
                                onChange={(e) => setSoDem(e.target.value)}
                                required
                                type="number"
                                error={!!soDemErr}
                                helperText={soDemErr}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <InputMui
                                label={t("res.serviceProvided") + " *"}
                                name="tenDichVu"
                                value={tenDichVu ? tenDichVu : ""}
                                require
                                onChange={(e) => setTenDichVu(e.target.value)}
                                error={tenDichVuErr}
                                helperText={tenDichVuErr}
                            ></InputMui>
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={11} sm={11} md={11} lg={11} xl={9}>
                            <SingleSelectMui
                                value={dmTyGiaId ? dmTyGiaId : 1}
                                fullWidth
                                onChange={(e) => setDmTyGiaId(e.target.value)}
                                label={t("exchangeRate.currency")}
                                options={tiGia}
                            ></SingleSelectMui>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={22}>
                            <FormControl
                                variant="outlined"
                                sx={{
                                    width: "100%",
                                    "& .MuiTypography-root": {
                                        fontSize: FONT_SIZE,
                                    },
                                }}
                            >
                                <InputLabel
                                    sx={{
                                        fontSize: FONT_SIZE,
                                        transform:
                                            dmDichVus?.length > 0
                                                ? null
                                                : "translate(14px, 10px) scale(1)",
                                    }}
                                >
                                    {t("dichVuBaoGom")}
                                </InputLabel>
                                <Select
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                maxHeight: MAX_HEIGHT_SELECT, // Giới hạn chiều cao tối đa
                                                overflowY: "auto", // Cho phép cuộn khi danh sách dài
                                            },
                                        },
                                    }}
                                    size="small"
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={dmDichVus} // Ensure this is always an array
                                    onChange={handleChangeDv}
                                    inputProps={{
                                        style: { fontSize: FONT_SIZE },
                                    }}
                                    input={
                                        <OutlinedInput
                                            label={t("typeService")}
                                        />
                                    }
                                    renderValue={(selected) => (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                                gap: 0.5,
                                            }}
                                        >
                                            {selected.map((dvid) => (
                                                <Chip
                                                    key={dvid}
                                                    label={
                                                        dataDichVu?.find(
                                                            (e) => e.id === dvid
                                                        ).value
                                                    }
                                                />
                                            ))}
                                        </Box>
                                    )}
                                >
                                    {dataDichVu?.map((option) => (
                                        <MenuItem
                                            key={option.id}
                                            value={option.id}
                                        >
                                            <Checkbox
                                                checked={
                                                    dmDichVus?.indexOf(
                                                        option.id
                                                    ) > -1
                                                }
                                            />
                                            <ListItemText
                                                primary={option.value}
                                                sx={{
                                                    "& span": {
                                                        fontSize: FONT_SIZE,
                                                    },
                                                }}
                                            />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "15px" }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={23}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                    <Space.Compact
                                        style={{
                                            width: "100%",
                                            marginBottom: 15,
                                            height: "56%",
                                        }}
                                    >
                                        <Input
                                            style={{
                                                width: "25%",
                                                fontSize: FONT_SIZE,
                                            }}
                                            readOnly
                                            defaultValue={t("point")}
                                        />
                                        <InputMui
                                            xs={{
                                                width: "75%",
                                                borderRadius: "4px",
                                            }}
                                            label={t("location.country")}
                                            select
                                            SelectProps={{
                                                MenuProps: {
                                                    sx: { height: "300px" },
                                                },
                                            }}
                                            fullWidth
                                            name="quocGiaId"
                                            onChange={handleChangeQG_DiemDi}
                                            value={quocGiaId}
                                        >
                                            {dataQuocGia.map((option) => (
                                                <MenuItem
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </InputMui>
                                    </Space.Compact>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={13}>
                                    <Cascader
                                        style={{
                                            width: "100%",
                                            borderRadius: "4px",
                                        }}
                                        size="large"
                                        options={dataTinhTP}
                                        onChange={onChangeCascader_diemDi}
                                        showSearch={{
                                            filterCascader,
                                        }}
                                        placeholder={
                                            t("location.city") +
                                            " - " +
                                            t("location.district") +
                                            " - " +
                                            t("location.wards")
                                        }
                                        onSearch={(value) => console.log(value)}
                                        value={
                                            diaDiem !== null
                                                ? diaDiem.split(",")
                                                : []
                                        }
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{ height: heightScroll }}>
                        {/* Cột chứa danh sách */}
                        <Col xs={24} sm={24} md={24} lg={24} xl={23}>
                            {items.map((item, index) => (
                                <Row key={index} style={{ width: "100%" }}>
                                    <Col
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={10}
                                    >
                                        <Space.Compact
                                            style={{
                                                width: "100%",
                                                marginBottom: 15,
                                                height: "56%",
                                            }}
                                        >
                                            <Input
                                                style={{
                                                    width: "25%",
                                                    borderRadius: "4px",
                                                    fontSize: FONT_SIZE,
                                                }}
                                                readOnly
                                                defaultValue={t("destination")}
                                            />
                                            <InputMui
                                                xs={{
                                                    width: "75%",
                                                    borderRadius: "4px",
                                                }}
                                                SelectProps={{
                                                    MenuProps: {
                                                        sx: {
                                                            height: MAX_HEIGHT_SELECT,
                                                        },
                                                    },
                                                }}
                                                label={t("location.country")}
                                                select
                                                fullWidth
                                                name="denDmQuocGiaId"
                                                onChange={(event) =>
                                                    handleChangeQG_item(
                                                        event,
                                                        index
                                                    )
                                                }
                                                value={item.denDmQuocGiaId}
                                            >
                                                {dataQuocGia.map((option) => (
                                                    <MenuItem
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </InputMui>
                                        </Space.Compact>
                                    </Col>
                                    <Col
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={13}
                                    >
                                        <Cascader
                                            style={{
                                                width: "100%",
                                                borderRadius: "4px",
                                            }}
                                            size="large"
                                            options={item.TinhTp}
                                            onChange={(
                                                value,
                                                selectedOptions
                                            ) =>
                                                onChangeCascader_item(
                                                    value,
                                                    selectedOptions,
                                                    index
                                                )
                                            }
                                            showSearch={{
                                                filterCascader,
                                            }}
                                            placeholder={
                                                t("location.city") +
                                                " - " +
                                                t("location.district") +
                                                " - " +
                                                t("location.wards")
                                            }
                                            onSearch={(value) =>
                                                console.log(value)
                                            }
                                            value={
                                                item.diemDen.length > 0
                                                    ? item.diemDen.split(",")
                                                    : []
                                            }
                                        />
                                    </Col>
                                    {items.length > 1 ? (
                                        <RemoveCircleOutlineIcon
                                            style={{
                                                marginTop: "10px",
                                                marginLeft: "10px",
                                            }}
                                            onClick={(e) =>
                                                handleRemoveField(index)
                                            }
                                        />
                                    ) : null}
                                </Row>
                            ))}
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={1}>
                            <AddBoxIcon
                                style={{ marginTop: "10px" }}
                                onClick={() => handleAddField()}
                            />
                        </Col>
                    </Row>

                    {/*<AddBoxIcon*/}
                    {/*    onClick={() => handleAddField()}*/}
                    {/*    style={{*/}
                    {/*        position: "absolute",*/}
                    {/*        right: "-25px", // Điều chỉnh giá trị này để dấu cộng gần hơn*/}
                    {/*        top: "29%",*/}
                    {/*        transform: "translateY(-50%)",*/}
                    {/*        cursor: "pointer",*/}
                    {/*        fontSize: "24px",*/}
                    {/*    }}*/}
                    {/*/>*/}
                    {/*</div>*/}
                </Box>
                <Form
                    onFinishFailed={onFinishFailed}
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                    colon={false}
                    {...formItemLayout}
                    initialValues={{
                        items: [{}],
                    }}
                    noValidate={true}
                >
                    {dataRender()}
                    <Form.Item
                        wrapperCol={{
                            span: 20,
                            offset: 6,
                        }}
                    >
                        <ButtonSubmit
                            handleClear={handleClear}
                            handleCancels={handleCancel}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};
export default ModalPRAction;
